<template>
  <div>
    <div class="container" v-if="$route.params.msg">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">{{ $route.params.msg_header }}</h4>
        {{ $route.params.msg }}
      </div>
    </div>

    <div class="container header">
      <div class="header1">Andrea Zimmermann-Wagner</div>
      <div class="header2">Rechtsanwältin</div>
    </div>


    <div class="jumbciotron">
      <div class="container intro">
        <p>Mein Berufsleben nähert sich dem Ende. Nach fast 40-jähriger Anwaltstätigkeit nehme ich keine neuen Mandate mehr an und ist das Büro nicht mehr jederzeit besetzt.</p>

        <p>
          Natürlich stehe ich Ihnen bei Fragen zu bestehenden Mandaten weiterhin zuverlässig zur Verfügung.
          Zur Kontaktaufnahme schreiben Sie zweckmäßigerweise eine kurze E-Mail unter <a href="mailto:ra@kanzlei-zimmermann-wagner.de">ra@kanzlei-zimmermann-wagner.de</a> und ich setze mich mit Ihnen in Verbindung.
        </p>

        <p>Für das in den vielen Jahren entgegengebrachte Vertrauen bedanke ich mich ganz herzlich.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
